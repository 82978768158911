
import useEmitter from "@/composables/Emmiter";
import Api from "@/services/Api";
import { defineComponent, onMounted, ref, Ref, watch } from "vue";

interface atendimento {
  placa: string;
  status: string;
}

interface dashboardFilter {
  idLojas: string;
  idGrupos: string;
  data: any;
  regiao: any;
}

export default defineComponent({
  name: "modalAtendimentos",
  emits: [],
  props: {},
  setup() {
    const emitter = useEmitter();
    const atendimentosRender: Ref<atendimento[]> = ref([]);

    const dashboardFilter: Ref<dashboardFilter> = ref({
      idLojas: "",
      idGrupos: "",
      data: "",
      regiao: "",
    });

    async function getDashboardUltimosAtendimentos() {
      const { data } = await Api.get("getDashboardUltimosAtendimentos", { ...dashboardFilter.value });
      const atend = data.atendimento;
      const atendimentosRenderValue = atend.map(a => ({ placa: a.placaVeiculo, status: a.status }));
      atendimentosRender.value = atendimentosRenderValue;
    }

    emitter.on("filtro-dashboard", async params => {
      dashboardFilter.value = {
        idGrupos: params.grupoEloja.grupo,
        idLojas: params.grupoEloja.loja,
        data: params.grupoEloja.data,
        regiao: params.grupoEloja.regiao,
      };
    });

    watch(
      () => [dashboardFilter.value.idLojas, dashboardFilter.value.idGrupos, dashboardFilter.value.data, dashboardFilter.value.regiao],
      () => {
        getDashboardUltimosAtendimentos();
      }
    );
    onMounted(() => {
      getDashboardUltimosAtendimentos();
    });

    return { atendimentosRender };
  },
});


import {
  defineComponent,
  Ref,
  ref,
  onMounted,
watch,
} from "vue";
import { Modal } from "bootstrap";
import Title from "@/components/Title.vue"
import {useTicketsStore} from "@/store/TicketsStore"
import ModalTickets from "@/views/dashboard/components/ModalTickets.vue"
import Api from "@/services/Api";

interface Category {
  name: string,
  total: number
}
interface Tickets {
  id: number,
  category: string,
  title: string,
  store_name: string,
  store_contact: string,
  description: string,
  resolution: string,
  cnpj: string,
  erp: string,
  store_manager: string,
  solicitante: string,
  report_date: string,
  status: string,
  support: string
}

interface Status {
  name: string,
  total: number
}

export default defineComponent({
  name: "TicketsInfo",

  components: {
    Title,
    ModalTickets
  },

  setup() {

    const categories: Ref<Category[]> = ref([]);
    const arrayStatus: Ref<Status[]> = ref([]);
    const status: Ref<any> = ref({})
    const tickets: Ref<Tickets[]> = ref([]);
    const totalTickets: Ref<Tickets[]> = ref([]);
    const ticketStore = useTicketsStore();
    const start_date: Ref<string> = ref('');
    const end_date: Ref<string> = ref('');

    const loadingStatus: Ref<boolean> = ref(false)
    const loadingTickets: Ref<boolean> = ref(false)

    async function getTicketsByStatus(status: string[]){
      const category = ticketStore.getCategory
      loadingTickets.value = true
      await ticketStore.getTickets(82, category, status, ticketStore.getDate[0], ticketStore.getDate[1])
      tickets.value = ticketStore.tickets
      loadingTickets.value = false
    }

    async function sendCategories(category:string){
      ticketStore.sendCategory(category)
    }

    function sendStatus(status){
      ticketStore.sendStatus(status)
    }

    async function getStatus(idGrupo: number, firstStatusActive = false){
      status.value = []
      
      try{
        const category = ticketStore.getCategory
        loadingStatus.value = true
        const { data } = await Api.post('getDashboardTickets', { idGrupo, category, start_date: ticketStore.getDate[0], end_date: ticketStore.getDate[1]})
        // this.ticketsByStatus = data
        data.forEach((item) => {
          if(status.value[item.status]){
            status.value[item.status] = status.value[item.status] + 1
          }else{
            status.value[item.status] = 1
          }
        })

        const newArrayStatus: Status[] = []

        for (const property in status.value){
          const name = property
          const total = status.value[property]
            newArrayStatus.push({
              name,
              total
            })
        }
        arrayStatus.value = newArrayStatus
        loadingStatus.value = false
        if(arrayStatus.value[0]){
          getTicketsByStatus([arrayStatus.value[0].name])
          if(firstStatusActive) sendStatus(arrayStatus.value[0].name)
        }
      }catch(error){
        console.log(error)
      }
    }

    async function getTotalTickets(idGrupo: number, start_date: string, end_date: string){
      const { data } = await Api.post('getDashboardTickets', { idGrupo, start_date, end_date})
      totalTickets.value = data
    }

    function openModal(ticket: Tickets){
      ticketStore.sendTicket(ticket)
      const ticketModal = new Modal(document.getElementById('ModalTickets'))
      ticketModal.show()
    }

    watch(
      () => ticketStore.getCategory,
      () => getStatus(82, true)
    )

    watch(
      () => ticketStore.getDate,
      async () => {
        start_date.value = ticketStore.getDate[0]
        end_date.value = ticketStore.getDate[1]
        
        await ticketStore.getTicketsByCategory(82, start_date.value, end_date.value)
        categories.value = ticketStore.getCategories
        await getTotalTickets(82, start_date.value, end_date.value)
      }
    )

    return {
      history,
      categories,
      arrayStatus,
      getTicketsByStatus,
      sendCategories,
      tickets,
      ticketStore,
      openModal,
      totalTickets,
      sendStatus,
      loadingStatus,
      loadingTickets
    };
  },
});

import Api from "@/services/Api";
import { defineStore } from "pinia";

interface Category {
  name: string,
  total: number
}

interface Tickets {
  id: number,
  category: string,
  title: string,
  store_name: string,
  store_contact: string,
  description: string,
  resolution: string,
  cnpj: string,
  erp: string,
  store_manager: string,
  solicitante: string,
  report_date: string,
  status: string,
  support: string
}


export const useTicketsStore = defineStore("TicketsStore", {
  state: () => {
    return {
      categories: [] as Category[],
      ticketsByStatus: [] as Tickets[],
      category: '',
      status: '',
      ticket: {} as Tickets,
      rangeDate: [] as string[],
      loadingCategory: false
    }
  },
  actions: {
    async getTicketsByCategory(idGrupo: number, start_date: string, end_date: string) {
      this.clearCategories()
      try{
        this.loadingCategory = true
        const { data } = await Api.post('getDashboardTicketsByCategory', { idGrupo , start_date, end_date }) 
        for (const category in data){
          const name = category
          let total = 0;
          for(const status in data[category]){
            total = total + data[category][status].length
          }
            this.categories.push({
              name,
              total
            })
        }
        if(this.categories[0].name){
          this.category = this.categories[0].name
        }
        this.loadingCategory = false
      }catch(error){
        console.log(error)
      }
    },
    async getTickets(idGrupo: number, category?: string, status?: string[], start_date?: string, end_date?: string){
      try{
        //trazer os tickets de acordo com o range de data do dashboard
        const { data } = await Api.post('getDashboardTickets', { idGrupo, category, status, start_date, end_date })
        this.ticketsByStatus = data
      }catch(error){
        console.log(error)
      }
    },
    clearCategories(){
      this.categories = []
    },
    sendCategory(category: string){
      this.category = category
    },
    sendStatus(status: string){
      this.status = status
    },
    sendTicket(ticket: Tickets){
      this.ticket = ticket
    },
    sendDate(arrayDate: string[]){
      this.rangeDate = arrayDate
    }
  },
  getters: {
    getCategories(): Category[] {
      return this.categories
    },
    
    tickets(): Tickets[] {
      return this.ticketsByStatus
    },
    getCategory(): string {
      return this.category
    },
    getStatus(): string {
      return this.status
    },
    getTicket(): Tickets {
      return this.ticket
    },
    getDate(): string[] {
      return this.rangeDate
    }
  }
});

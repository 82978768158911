
import Api from "@/services/Api";
import { defineComponent, Ref, ref, onMounted, watch } from "vue";
import type { PropType } from "vue";
import useAlert from '@/composables/Alert'
import useEmitter from "@/composables/Emmiter";
import Button from "@/components/Button.vue"
import { useTicketsStore } from "@/store/TicketsStore";
import Loader from '@/components/Loader.vue'
import moment from "moment";
import Comments from "@/views/dashboard/components/Comments.vue"
import { number } from "yup";

interface Ticket {
  id: number | null,
  category: string,
  title: string,
  store_name: string,
  store_contact: string,
  description: string,
  resolution: string,
  cnpj: string,
  erp: string,
  store_manager: string,
  solicitante: string,
  report_date: string,
  status: string,
  support: string,
}

export default defineComponent({
  name: "ModalTickets",
  components: {
    Button,
    Comments
},
  props: {
    usuario: Object as PropType<any>,
    fechaModal: Function,
    abreModal: Function,
    ticket: Object as PropType<any>
  },

  setup(props) {

    const emitter = useEmitter()
    const ticketStore = useTicketsStore();

    const usuario: Ref<any> = ref({});
    const arrayLojas: Ref<any[]> = ref([]);
    const usuarioLojas: Ref<any> = ref([]);

    const loadSave: Ref<boolean> = ref(false);
    const loadExcluir: Ref<boolean> = ref(false);
    const loadRemoveUsuario: Ref<boolean> = ref(false);
    const loadResetarSenha: Ref<boolean> = ref(false);
    const dialogVisible: Ref<boolean> = ref(false);

    const clikouResetarSenha: Ref<boolean> = ref(false);
    const novaSenha: Ref<string> = ref('ilub');


    const inputComMascara: Ref<string> = ref('');
    const adicionarUsuarioEmNovaLoja: Ref<boolean> = ref(false)

    const arrayComments: Ref<any[]> = ref([]);
    const idLoja: Ref<any> = ref(null);

    const ticket: Ref<Ticket> = ref({
      id: null,
      cnpj: '',
      store_name: '',
      store_manager: '',
      solicitante: '',
      store_contact: '',
      erp: '',
      title: '',
      report_date: '',
      description: '',
      resolution: '',
      category: '',
      status: '',
      support: '',
    })

    function formatarInput(type: string, event) {

      if (type === 'documento') {
        inputComMascara.value = event.replace(/\D/g, "");
        inputComMascara.value = inputComMascara.value.replace(/(\d{3})(\d)/, "$1.$2");
        inputComMascara.value = inputComMascara.value.replace(/(\d{3})(\d)/, "$1.$2");
        inputComMascara.value = inputComMascara.value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

        usuario.value.documento = inputComMascara.value;
      }

      if (type === 'telefone') {
        inputComMascara.value = event.replace(/\D/g, "");
        inputComMascara.value = inputComMascara.value.replace(/^(\d\d)(\d)/g, "($1) $2");
        inputComMascara.value = inputComMascara.value.replace(/(\d{5})(\d)/, "$1-$2");

        usuario.value.telefone = inputComMascara.value;
      }

      return ''

    }


    watch(
      () => ticketStore.getTicket, 
      () => {
        ticket.value = ticketStore.getTicket
        ticket.value.report_date = moment(ticket.value.report_date, 'YYYY-MM-DD HH:mm:ss').format("YYYY/MM/DD")
        }, 
      { deep: true }
      )

    onMounted(async()=>{

    })
   
    return {
      usuario,
      formatarInput,
      loadSave,
      loadRemoveUsuario,
      loadExcluir,
      arrayLojas,
      adicionarUsuarioEmNovaLoja,
      clikouResetarSenha,
      novaSenha,
      loadResetarSenha,
      usuarioLojas,
      idLoja,
      dialogVisible,
      ticket,
      arrayComments,
      moment
    };
  },
});


import { DefineComponent, defineComponent, onMounted, onUnmounted, Ref, ref, watch } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import AuthService from "@/services/AuthService";
import { useRouter } from "vue-router";
import VueApexCharts from "vue3-apexcharts";
import Api from "@/services/Api";
import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import ModalAtendimentos from "./modalAtendimentos.vue";
import { Modal } from "bootstrap";
import useEmitter from "@/composables/Emmiter";
import KTLoader from "@/components/Loader.vue";
import { loaderLogo } from "@/core/helpers/config";
import Title from "@/components/Title.vue"
import TicketsInfo from "@/views/dashboard/components/TicketsInfo.vue"
import { useTicketsStore } from "@/store/TicketsStore";
import moment from "moment";


interface dashboardFilter {
  idLojas: string;
  idGrupos: string;
  data: any;
  regiao: any;
}

interface Loja {
  integracoes: any[];
  integrada: number;
  total: number;
}

interface Recusas {
  produto: number;
  valor: number;
}

interface ContentDashboard {
  total: { qtd: number; valor: number };
  manual: { qtd: number; valor: number };
  buscaPlaca: { qtd: number; valor: number };
  aberto: { qtd: number; valor: number };
  enviado: { qtd: number; valor: number };
  fechado: { qtd: number; valor: number };
  vendido: { qtd: number; valor: number };
  cancelado: { qtd: number; valor: number };
  andamento: { qtd: number; valor: number };
  precificar: { qtd: number; valor: number };
  pendente: { qtd: number; valor: number };
  transmitido: { qtd: number; valor: number };
  consulta: { qtd: number; valor: number };
  recusa: { qtd: number; valor: number };
  orcamentos: { qtd: number; valor: number };
  valorOfertado: { valor: number };
  produtos: { qtd: number };
}

interface Store {
  finalPlano: string;
  id: number;
  inicioPlano: string;
  nome: string;
  grupo: string;
  plano: string;
  progress: number;
  status: number | boolean;
}

interface StoreFilter {
  nomeFantasia: string;
  grupo: string;
  data: any;
  status: string;
}

interface ModalAtendimentosInterface {}
interface TitleInterface {}


export default defineComponent({
  name: "Dashboard",

  components: { 
    ModalAtendimentos: ModalAtendimentos as unknown as DefineComponent<{}, {}, ModalAtendimentosInterface>, 
    KTLoader ,
    Title: Title as unknown as DefineComponent<{}, {}, TitleInterface>,
    TicketsInfo
  },
  setup() {
    let auxModal;
    const emitter = useEmitter();
    const router = useRouter();
    const store = useStore();
    const isLoading: Ref<boolean> = ref(true);
    const isLoadingOS: Ref<boolean> = ref(true);
    const isLoadingStoresErps: Ref<boolean> = ref(true);
    const openDropdown: Ref<boolean> = ref(true);
    const updateChart: Ref<boolean> = ref(true);
    const updateChartOS: Ref<boolean> = ref(true);
    const mesHistLoja = ref("");
    const mesHistOS = ref("");
    const totalLojas: Ref<number> = ref(0);
    const lojasIntegradas: Ref<number> = ref(0);
    const ticketStore = useTicketsStore()

    const value1 = ref("");
    const value2 = ref([]);

    const storeFilter: Ref<StoreFilter> = ref({ nomeFantasia: "", grupo: "", data: "", status: "" });

    const openModal = id => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    };

    const storeInt: Ref<Loja> = ref({
      integracoes: [],
      integrada: 0,
      total: 0,
    });

    const declinediLub: Ref<Recusas> = ref({
      produto: 0,
      valor: 0,
    });

    const groupStore: Ref<any> = ref("");
    const lojaSpecify: Ref<any> = ref("");
    const specificFormatData = (date: any) => new Date(date).toISOString().substring(0, 19).replace("T", " ");

    const dashboardFilter: Ref<dashboardFilter> = ref({
      idLojas: "",
      idGrupos: "",
      data: "",
      regiao: "",
    });

    const contentDashboard: Ref<ContentDashboard> = ref<ContentDashboard>({
      total: { qtd: 0, valor: 0 },
      manual: { qtd: 0, valor: 0 },
      buscaPlaca: { qtd: 0, valor: 0 },
      aberto: { qtd: 0, valor: 0 },
      enviado: { qtd: 0, valor: 0 },
      fechado: { qtd: 0, valor: 0 },
      vendido: { qtd: 0, valor: 0 },
      cancelado: { qtd: 0, valor: 0 },
      andamento: { qtd: 0, valor: 0 },
      precificar: { qtd: 0, valor: 0 },
      pendente: { qtd: 0, valor: 0 },
      transmitido: { qtd: 0, valor: 0 },
      consulta: { qtd: 0, valor: 0 },
      recusa: { qtd: 0, valor: 0 },
      orcamentos: { qtd: 0, valor: 0 },
      valorOfertado: { valor: 0 },
      produtos: { qtd: 0 },
    });

    const dataAtualMounted: Ref<any> = ref([]);

    const changeDrop = () => {
      openDropdown.value = !openDropdown.value;
    };

    const getMonthRange = () => {
      try {
        //essa parte pega o ultimo dia do mes atual, e o primeiro dia do mes atual.
        const today = new Date(); // Obtém a data atual
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1); // Define o primeiro dia do mês atual
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Define o último dia do mês atual
        dataAtualMounted.value = [firstDayOfMonth, lastDayOfMonth]; // Define o intervalo do mês atual como um array em dataAtualMounted
        mesHistLoja.value = dataAtualMounted.value[1];
        mesHistOS.value = dataAtualMounted.value[0];
        /* essa parte pega o ultimo dia do mes atual, e o primeiro dia do mes anterior.
        const today = new Date(); // Obtém a data atual
        const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1); // Define o primeiro dia do mês anterior
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Define o último dia do mês atual
        // Ajusta a data do último dia do mês atual
        lastDayOfMonth.setDate(lastDayOfMonth.getDate());
        dataAtualMounted.value = [firstDayOfLastMonth, lastDayOfMonth]; // Define o intervalo do mês anterior e do mês atual como um array em dataAtualMounted
        */
      } catch (error) {
        console.log(error);
      } finally {
        value2.value = dataAtualMounted.value;
      }
    };

    const series: Ref<any> = ref([]);
    const chartOptionsAtendimentos = ref({
      chart: { width: 200, type: "pie" },
      labels: ["Busca Placa", "Busca Manual"],
      legend: { show: false },
      colors: ["#3699FF", "#000000"],
      stroke: {
        width: 0,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 100,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    });

    const shortcuts = [
      {
        text: "This month",
        value: [new Date(), new Date()],
      },
      {
        text: "This year",
        value: () => {
          const end = new Date();
          const start = new Date(new Date().getFullYear(), 0);
          return [start, end];
        },
      },
      {
        text: "Last 6 months",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setMonth(start.getMonth() - 6);
          return [start, end];
        },
      },
    ];

    const seriesOS: Ref<any> = ref([]);
    const chartOptionsOS = ref({
      chart: { width: 200, type: "donut" },
      labels: ["Finalizados", "Orçamentos", "Consultas"],
      legend: { show: false },
      colors: ["#1BC5BD", "#3699FF", "#FFB822"],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      align: "center",
      verticalAlign: "middle",
      text: "CAMP Tec",
      stroke: {
        width: 0,
      },
    });

    const shortcutsOS = [
      {
        text: "This month",
        value: [new Date(), new Date()],
      },
      {
        text: "This year",
        value: () => {
          const end = new Date();
          const start = new Date(new Date().getFullYear(), 0);
          return [start, end];
        },
      },
      {
        text: "Last 6 months",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setMonth(start.getMonth() - 6);
          return [start, end];
        },
      },
    ];

    const seriesGrafico: Ref<any> = ref([]);

    const MONTHS = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
    const MONTHS_FULL = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

    const categories: Ref<any> = ref([
      new Date("2018-01-19T00:00:00.000Z"),
      new Date("2018-02-19T01:30:00.000Z"),
      new Date("2018-03-19T02:30:00.000Z"),
      new Date("2018-04-19T03:30:00.000Z"),
      new Date("2018-05-19T04:30:00.000Z"),
      new Date("2018-06-19T05:30:00.000Z"),
      new Date("2018-07-19T06:30:00.000Z"),
    ]);

    const categoriesWithMonth = seriesGrafico.value?.data?.map(item => {
      const date = new Date(item.x);
      return MONTHS[date.getMonth()] + "/" + date.getFullYear();
    });
    const chartOptionsGrafico = ref({
      chart: { height: 350, type: "area", toolbar: { show: false } },
      dataLabels: { enabled: false },
      stroke: { curve: "smooth" },
      xaxis: {
        type: "category",
        categories: categoriesWithMonth,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          const mes = w.config.series[seriesIndex].data[dataPointIndex].x;
          const adicionadas = w.config.series[seriesIndex].data[dataPointIndex].z;
          const acumuladas = w.config.series[seriesIndex].data[dataPointIndex].y;
          return `<div class="p-4"><strong> ${mes}</strong> <br> Adicionadas:<strong> ${adicionadas}</strong> <br> Total de lojas: <strong>${acumuladas}</strong></div>`;
        },
      },
      yaxis: {
        show: false,
      },
    });

    // "chartOptionsHistOS" :series="seriesHistOS"
    // const categoriesOS: Ref<any> = ref(["01/01/2011 GMT", "01/02/2011 GMT", "01/03/2011 GMT", "01/04/2011 GMT", "01/05/2011 GMT", "01/06/2011 GMT"]);
    const categoriesOSs: Ref<any> = ref(["11/01/2022 GMT", "12/01/2022 GMT", "01/01/2023 GMT", "02/01/2023 GMT", "03/01/2023 GMT"]);
    const seriesHistOS: Ref<any> = ref([]);
    const categoriesOS: Ref<any> = ref([]);
    let orcamentos = [];
    let finalizados = [];
    let consultas = [];

    const chartOptionsHistOS = ref({
      chart: {
        type: "bar",
        height: 350,
        colors: ["#1BC5BD", "#3699FF", "#FFB822"],
        stacked: true,
        // stackType: "100%",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          // endingShape: "rounded",
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: "datetime",
        categories: categoriesOS.value,
      },
      tooltip: {
        enabled: true,
        shared: false,
        followCursor: false,
        style: {
          fontSize: "14px",
          fontWeight: 600,
        },
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          const mes = MONTHS_FULL[dataPointIndex];
          const value = w.config.series[seriesIndex].data[dataPointIndex];

          const orcamentosValue = w.config.series[0].data[dataPointIndex];
          const finalizadosValue = w.config.series[1].data[dataPointIndex];
          const consultasValue = w.config.series[2].data[dataPointIndex];

          // Calcula o total do valor da série selecionada
          const totalValue = orcamentosValue + finalizadosValue + consultasValue;

          // Calcula a porcentagem em relação ao total
          const percent = ((value / totalValue) * 100).toFixed(2);

          return `<div class="p-4"><strong></strong>${w.config.series[seriesIndex].name}: <strong>${percent}%</strong></div>`;
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    });

    async function getAttendanceDashboard() {
      isLoading.value = true;
      try {
        const { data } = await Api.get("getAttendanceDashboard", { ...dashboardFilter.value });
        const atd = data.atendimento;

        contentDashboard.value = {
          total: atd.consultas,
          manual: atd.manual,
          buscaPlaca: atd.buscaPlaca,
          aberto: atd.aberto,
          enviado: atd.enviado,
          fechado: atd.fechado,
          vendido: atd.vendido,
          cancelado: atd.cancelado,
          andamento: atd.andamento,
          precificar: atd.precificar,
          pendente: atd.pendente,
          transmitido: atd.transmitido,
          consulta: atd.consulta,
          recusa: atd.recusa,
          produtos: { qtd: atd.transmitido.qtdProduto },
          valorOfertado: {
            valor: atd.andamento.valor + atd.enviado.valor + atd.vendido.valor + atd.precificar.valor + atd.pendente.valor + atd.transmitido.valor,
          },
          orcamentos: {
            qtd:
              atd.andamento.qtd + atd.enviado.qtd + atd.vendido.qtd + atd.precificar.qtd + atd.pendente.qtd + (atd.fechado.qtd - atd.transmitido.qtd),
            valor:
              atd.andamento.valor +
              atd.enviado.valor +
              atd.vendido.valor +
              atd.precificar.valor +
              atd.pendente.valor +
              (atd.fechado.valor - atd.transmitido.valor),
          },
        };
        const { buscaPlaca, manual, transmitido, orcamentos, aberto } = contentDashboard.value;
        series.value = [buscaPlaca.qtd, manual.qtd];
        seriesOS.value = [transmitido.qtd, orcamentos.qtd, aberto.qtd];
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    }

    async function getDashboardIntegratedStores() {
      isLoadingStoresErps.value = true;
      try {
        const { data } = await Api.get("getDashboardIntegratedstores", {
          ...dashboardFilter.value,
        });
        const totalLojas = data.integracoes.reduce((acc, cur) => acc + cur.lojas, 0);
        storeInt.value = {
          integracoes: data.integracoes
            .map(nomeIntegracao => nomeIntegracao)
            .sort((a: any, b: any) => b.lojas - a.lojas || a.nome.localeCompare(b.nome)),
          integrada: 0,
          total: totalLojas,
        };
      } catch (error) {
        console.log(error);
      } finally {
        isLoadingStoresErps.value = false;
      }
    }

    async function getReasonsDecline() {
      try {
        const { data } = await Api.get("getDashboardOfferDeclined");

        const produtoQtd = data.ofertasRecusadas.filter(obj => obj.nome.toLowerCase().includes("produto")).map(obj => obj.qtd)[0];
        const valorQtd = data.ofertasRecusadas.filter(obj => obj.nome.toLowerCase().includes("valor")).map(obj => obj.qtd)[0];

        declinediLub.value = {
          produto: typeof produtoQtd === "number" ? produtoQtd : 0,
          valor: typeof valorQtd === "number" ? valorQtd : 0,
        };
      } catch (error) {
        console.log(error);
      }
    }

    async function getDashboardHistoricoLojas() {
      updateChart.value = true;
      try {
        const { data } = await Api.get("getDashboardHistoricoLojas", { ...dashboardFilter.value, data: formatDateHistLoja(mesHistLoja.value) });
        totalLojas.value = data.total;
        lojasIntegradas.value = data.integrada;

        // Ordena o array historico pela data, em ordem crescente
        const historicoOrdenado = data.historico.reverse();
        //sort((a, b) => new Date(a.mes).getTime() - new Date(b.mes).getTime());

        // Cria a série de dados para o gráfico
        const series: Ref<any> = ref(
          historicoOrdenado.map(item => {
            const formattedDate = formatDate(item.mes);
            return {
              x: formattedDate,
              y: item.acumulado,
              z: item.total,
            };
          })
        );
        seriesGrafico.value = [
          {
            name: "Total",
            data: series.value.map(item => ({ x: item.x, y: item.y, z: item.z })),
          },
        ];
      } catch (error) {
        console.log(error);
      } finally {
        updateChart.value = false;
      }
    }

    async function getDashboardHistoricoOs() {
      updateChartOS.value = true;
      try {
        const { data } = await Api.get("getDashboardHistoricoAtendimentos", { ...dashboardFilter.value, data: formatDateHistLoja(mesHistOS.value) });

        const categories = data.atendimento
          .map(item => ({ mes: new Date(item.mes), formatted: formatDateOS(item.mes) }))
          .sort((a, b) => a.mes.getTime() - b.mes.getTime())
          .map(item => item.formatted);

        const orcamentos = data.atendimento.map(item => item.orcamentos);
        const finalizados = data.atendimento.map(item => item.finalizados);
        const consultas = data.atendimento.map(item => item.consultas); // Define as categorias do eixo x

        // seriesHistOS.value =

        chartOptionsHistOS.value.xaxis.categories = categories;
        seriesHistOS.value = [
          { name: "Orcamentos", data: orcamentos },
          { name: "Finalizados", data: finalizados },
          { name: "Consultas", data: consultas },
        ];

        // seriesHistOS.value = [
        //   { name: "PRODUCT A", data: [44, 55, 41, 67, 22, 43] },
        //   {
        //     name: "PRODUCT B",
        //     data: [13, 23, 20, 8, 13, 27],
        //   },
        //   {
        //     name: "PRODUCT C",
        //     data: [11, 17, 15, 15, 21, 14],
        //   },
        //   {
        //     name: "PRODUCT D",
        //     data: [21, 7, 25, 13, 22, 8],
        //   },
        // ];
      } catch (error) {
        console.log(error);
      } finally {
        updateChartOS.value = false;
      }
    }

    function formatDateOS(dateStr) {
      const date = new Date(dateStr);
      const monthNames = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
      const day = date.getDate().toString().padStart(2, "0");
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear().toString();
      // const hour = date.getUTCHours().toString().padStart(2, "0");
      // const minute = date.getUTCMinutes().toString().padStart(2, "0");
      // const second = date.getUTCSeconds().toString().padStart(2, "0");
      return `${month}/${day}/${year} GMT`;
    }

    function formatDate(dateStr) {
      // Criar uma nova instância de Date a partir da string de data fornecida
      const date = new Date(dateStr);

      // Obter a hora atual em UTC+3
      date.setUTCHours(date.getUTCHours() + 3);
      const monthNames = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
      const month = monthNames[date.getUTCMonth()];
      const year = date.getUTCFullYear().toString().substr(-2);
      return `${month}/${year}`;
    }

    const formatDateHistLoja = date => {
      // Verifica se a data é válida
      if (date instanceof Date && !isNaN(date.valueOf())) {
        // Obtém o ano, mês e dia da data
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        // Formata a data no formato desejado (YYYY/MM/DD)
        return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}T03:00:00.000Z`;
      }

      return null;
    };

    const userInfo = AuthService.getUsuarioAuth();
    const codNivel = userInfo.nivelCargo;

    function goToHome() {
      router.push({ name: "login" });
    }

    watch(
      () => value2.value,
      () => {
       ticketStore.sendDate([specificFormatData(value2.value[0]), specificFormatData(value2.value[1])])
      }
    )

    async function enviarEmit() {
      await emitter.emit("Dashboard", {
        dataSelecionada: `${specificFormatData(value2.value[0])},${specificFormatData(value2.value[1])}`,
      });
    }

    emitter.on("filtro-dashboard", async params => {
      dashboardFilter.value = {
        idGrupos: params.grupoEloja.grupo,
        idLojas: params.grupoEloja.loja,
        data: params.grupoEloja.data,
        regiao: params.grupoEloja.regiao,
      };
    });































    async function baixaAtendimento() {  
       
      const response = await Api.get("DownloadAtendimentos", { ...dashboardFilter.value }, { responseType: 'blob' });

      baixaArquivo(response, 'dashboard.xlsx')

    }

    async function baixaHistoricoLoja() {

      const response = await Api.get("downloadHistoricoLojas", { ...dashboardFilter.value, data: formatDateHistLoja(mesHistLoja.value) }, { responseType: 'blob' });

      baixaArquivo(response, 'lojas.xlsx')

    }

    function baixaArquivo(blobParam:any, name:string) {

      // window.open( URL.createObjectURL(response) )

      const url = URL.createObjectURL(new Blob([blobParam]));
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      link.click();
      URL.revokeObjectURL(url);

    }


    
























watch(
      () => value2.value,
      () => {
        dashboardFilter.value = {
          idGrupos: dashboardFilter.value.idGrupos ? dashboardFilter.value.idGrupos : "",
          data: `${specificFormatData(value2.value[0])},${specificFormatData(value2.value[1])}`,
          idLojas: dashboardFilter.value.idLojas ? dashboardFilter.value.idLojas : "",
          regiao: dashboardFilter.value.regiao ? dashboardFilter.value.regiao : "",
        };
      }
    );

    watch(
      () => mesHistLoja.value,
      () => getDashboardHistoricoLojas()
    );

    watch(
      () => mesHistOS.value,
      () => getDashboardHistoricoOs()
    );

    watch(
      () => [dashboardFilter.value.idLojas, dashboardFilter.value.idGrupos],
      () => {
        getDashboardHistoricoOs(), getDashboardHistoricoLojas();
      }
    );

    watch(
      () => [dashboardFilter.value.idLojas, dashboardFilter.value.idGrupos, dashboardFilter.value.data, dashboardFilter.value.regiao],
      () => {
        getAttendanceDashboard();
        getDashboardIntegratedStores();
        getReasonsDecline();
        // getDashboardHistoricoLojas();
        // getDashboardHistoricoOs();
        enviarEmit();
      }
    );

    // testar, encapsular a funcao de historicos e usar no oumounted, com uma condicao na funcao para q se existe tal parametro, execute, na montagem.
    onMounted(() => {
      getMonthRange();
      // getAttendanceDashboard();
      // getDashboardIntegratedStores();
      // getReasonsDecline();
      // getDashboardHistoricoLojas();
      // getDashboardHistoricoOs();

    });

    return {
      goToHome,
      value1,
      value2,
      shortcuts,
      series,
      chartOptionsAtendimentos,
      shortcutsOS,
      seriesOS,
      chartOptionsOS,
      groupStore,
      lojaSpecify,
      getAttendanceDashboard,
      contentDashboard,
      isLoading,
      seriesGrafico,
      chartOptionsGrafico,
      openDropdown,
      changeDrop,
      isLoadingStoresErps,
      storeInt,
      declinediLub,
      dashboardFilter,
      chartOptionsHistOS,
      seriesHistOS,
      storeFilter,
      console,
      ElConfigProvider,
      ptBr,
      dataAtualMounted,
      openModal,
      isLoadingOS,
      categoriesOS,
      loaderLogo,
      mesHistLoja,
      updateChart,
      updateChartOS,
      mesHistOS,
      totalLojas,
      lojasIntegradas,
      baixaHistoricoLoja,
      baixaAtendimento
    };
  },
});


import {
  defineComponent,
  Ref,
  ref,
watch,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Title from "@/components/Title.vue"
// import { useCommentStore } from "@/store/CommentStore";

export default defineComponent({
  name: "Comments",
  props: ['arrayComments'],
  components: {
    Field,
    Form,
    ErrorMessage,
    Title,
  },

  setup(props) {

    const arrayComments: Ref<any[]> = ref([]);
    const arrayUrls: Ref<any[]> = ref([]);


    function getUrls(listUrls){
      arrayUrls.value = []
      listUrls.forEach((item) => arrayUrls.value.push(item.url))
    }

    watch(
      () => props.arrayComments,
      () => {
        arrayComments.value = props.arrayComments
      }
    )


    return {
      history,
      arrayComments,
      arrayUrls,
      getUrls
    };
  },
});
